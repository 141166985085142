import {getBaseUrl} from "../../services/app.service";

const apiData = [
	{
		id: 1,
		name: "API WacePay Business",
		description: "Pour les entreprises et particuliers, offre des solutions de paiement sécurisé et gestion des transactions financières.",
		image: `/img/cardImages/wacepay.png`, // Remplacez par l'URL de votre image
		link: "wacepayBusiness"
	},
	{
		id: 2,
		name: "API NUFI SMS - GSM",
		description: "Envoyez des SMS GSM et des messages en masse via WhatsApp avec des fonctionnalités avancées.",
		image: `/img/cardImages/nufi-sms.png`, // Remplacez par l'URL de votre image
		link: "NufiSMSGSM"
	},
	{
		id: 3,
		name: "API NUFI SMS - Bulk WhatsApp",
		description: "Envoyez des messages en masse via WhatsApp pour une communication efficace et rapide.",
		image: `/img/cardImages/nufi-whatsapp.png`, // Remplacez par l'URL de votre image
		link: "NufiWhatsApp"
	},
	// Ajoutez d'autres API ici si nécessaire

];

export {apiData};