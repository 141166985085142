import React from 'react';
import './App.css';
import {DocBody} from "./pages/docBody/DocBody";
import 'bootstrap/dist/css/bootstrap.min.css';
import DocumentationNavbar from "./Components/DocumentationNavbar/DocumentationNavbar";
import {Provider} from "react-redux";
import {store} from "./store/store";

function App() {
	return (
		<Provider store={store}>
			<div className="app-container">
				<DocumentationNavbar/>
				<main className="main-content">
					<DocBody/>
				</main>
			</div>
		</Provider>
	);
}

export default App;
