import React from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDoc } from '../../store/reducers/docReducer';

const ServiceCard = ({ api }) => {
	const dispatch = useDispatch();

	const handleCardClick = (docType) => {
		dispatch(setCurrentDoc(docType));
	};

	React.useEffect(() => {
		addHoverEffect();
	}, []);
	return (
		<Card className="mb-4 service-card" style={cardStyle}>
			<div style={linkStyle} onClick={() => handleCardClick(api.link)}>
				<div style={imageContainerStyle}>
					<Card.Img variant="top" src={api.image} alt={api.name} style={imageStyle} />
				</div>
				<Card.Body>
					<Card.Title style={titleStyle}>{api.name}</Card.Title>
					<Card.Text style={descriptionStyle}>{api.description}</Card.Text>
				</Card.Body>
			</div>
		</Card>
	);
};

// Styles
const cardStyle = {
	width: '100%',
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	borderRadius: '8px',
	overflow: 'hidden',
	transition: 'transform 0.3s ease, box-shadow 0.3s ease',
	cursor: 'pointer',
	textDecoration: 'none',
};

const linkStyle = {
	color: 'inherit',
	textDecoration: 'none',
	display: 'block', // Pour s'assurer que tout le lien est cliquable
	height: '100%', // S'assurer que l'ancre occupe toute la hauteur de la carte
};

const imageContainerStyle = {
	width: '100%',
	overflow: 'hidden',
	position: 'relative',
	paddingBottom: '100%', // Hauteur de l'image 1:1
};

const imageStyle = {
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	objectFit: 'cover', // Assure que l'image couvre toute la zone
	transition: 'transform 0.3s ease',
};

const titleStyle = {
	fontSize: '1.5rem',
	fontWeight: 'bold',
};

const descriptionStyle = {
	fontSize: '1rem',
	color: '#555',
};

// Ajouter des styles pour l'animation au survol
const hoverStyle = {
	cardHover: {
		transform: 'translateY(-10px)',
		boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
	},
	imageHover: {
		transform: 'scale(1.1)',
	},
};

// Ajout de l'événement de survol pour l'animation
const addHoverEffect = () => {
	const cards = document.querySelectorAll('.service-card');
	cards.forEach((card) => {
		card.addEventListener('mouseover', () => {
			card.style.transform = hoverStyle.cardHover.transform;
			card.style.boxShadow = hoverStyle.cardHover.boxShadow;
			const img = card.querySelector('img');
			img.style.transform = hoverStyle.imageHover.transform;
		});

		card.addEventListener('mouseout', () => {
			card.style.transform = 'none';
			card.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
			const img = card.querySelector('img');
			img.style.transform = 'none';
		});
	});
};

export default ServiceCard;
