import React from 'react';
import { RedocStandalone } from 'redoc';
import { useSelector } from 'react-redux';
import Home from "../home/Home";

export const DocBody = () => {
	const { currentDoc, docs } = useSelector((state) => state.doc);

	return (
		<div className="doc-body-container">
			{
				currentDoc === 'Home' ? <Home/> :
					<RedocStandalone
					specUrl={docs[currentDoc]}
					options={{
						nativeScrollbars: true,
						hideLoading: true, // Cacher le chargement jusqu'à ce que tout soit prêt
						disableSearch: false, // Activer explicitement la recherche
						theme: {
							colors: {
								primary: {
									main: '#f78210',
								},
							},
							sidebar: {
								backgroundColor: "#3855a0",
								textColor: "#f1f1f1",
								arrow: {
									color: "#f1f1f1"
								},
								activeTextColor: "#f78210",
							},
							menu: {
								backgroundColor: "#ffffff",
							},
						},
						scrollYOffset: 70
					}}
				/>}
		</div>
	);
};