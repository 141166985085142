import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDoc } from '../../store/reducers/docReducer';
import './DocumentationNavbar.css';

const DocumentationNavbar = () => {
	const [scrolled, setScrolled] = useState(false);
	const dispatch = useDispatch();
	const currentDoc = useSelector((state) => state.doc.currentDoc);

	useEffect(() => {
		const handleScroll = () => {
			const isScrolled = window.scrollY > 50;
			if (isScrolled !== scrolled) {
				setScrolled(isScrolled);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [scrolled]);

	const handleNavClick = (docType) => {
		dispatch(setCurrentDoc(docType));
	};

	return (
		<Navbar
			expand="lg"
			className={`documentation-navbar ${scrolled ? 'scrolled' : ''}`}
			fixed="top"
		>
			<Container>
				<Navbar.Brand href="#" className="brand">
					<img
						src="http://localhost:3000/img/logo-digitwace.png"
						alt="Digitwace Logo"
						className="logo"
					/>
					<span className="brand-text">API Doc's</span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto">
						<Nav.Link
							onClick={() => handleNavClick('Home')}
							className={currentDoc === 'Home' ? 'active' : ''}
						>
							Home
						</Nav.Link>
						<Nav.Link
							onClick={() => handleNavClick('wacepayBusiness')}
							className={currentDoc === 'wacepayBusiness' ? 'active' : ''}
						>
							WacePay Business
						</Nav.Link>
						<NavDropdown
							title="NUFI SMS"
							id="basic-nav-dropdown"
							className={`custom-dropdown ${currentDoc.startsWith('Nufi') ? 'active' : ''}`}
						>
							<NavDropdown.Item
								onClick={() => handleNavClick('NufiWhatsApp')}
								className={currentDoc === 'NufiWhatsApp' ? 'active' : ''}
							>
								WhatsApp API
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => handleNavClick('NufiSMSGSM')}
								className={currentDoc === 'NufiSMSGSM' ? 'active' : ''}
							>
								SMS GSM API
							</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link
							onClick={() => handleNavClick('tutorials')}
							className={currentDoc === 'tutorials' ? 'active' : ''}
						>
							Tutorials
						</Nav.Link>
						<Nav.Link
							onClick={() => handleNavClick('PayIn')}
							className={currentDoc === 'PayIn' ? 'active' : ''}
						>
							PayIn (Coming Soon)
						</Nav.Link>
						<Nav.Link
							// onClick={() => handleNavClick('contact')}
							className={currentDoc === 'contact' ? 'active' : ''}
						>
							Contact
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default DocumentationNavbar;